import React from 'react'
import { FileLink } from './FileLink';

export type PageFilesProps = {
    files?: Array<any> | null
}

export const PageFiles: React.FC<PageFilesProps> = ({ files }) => {
    if (!files || files.length < 1) return null

    return (
        <div className='PageFiles'>

            <div className='PageFiles-inner inner'>
                <h3>Related Files</h3>
                <div className='PageFiles-fileLinks'>
                    {files?.map(file => <FileLink text={file.fileName} link={file.pageFile.publicURL} />)}
                </div>
            </div>
        </div>
    );
}